<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <v-tour
          name="myTour"
          v-if="iscoach"
          :steps="steps_coach"
          :options="myOptions"
        ></v-tour>
        <v-tour
          name="myTour"
          v-if="!iscoach"
          :steps="steps_user"
          :options="myOptions"
        ></v-tour>
        <Toast />
        <DataTable
          id="searchtabdatatable"
          ref="dt"
          :value="products"
          dataKey="id"
          :paginator="true"
          :rows="25"
          :filters="filters"
          filterDisplay="menu"
          :globalFilterFields="['name','nutshell','pricerange','metric']"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25, 50]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          sortField="metric"
          :sortOrder="-1"
          :loading="loading1"
          responsiveLayout="scroll"
          @filter="test"
          @value-change="test"
        >
          <template #empty>
            Currently no coaches match your needs (c.f.
            <router-link :to="{ name: 'redoquestionnaire' }"
              >questionnaire.</router-link
            >).
          </template>
          <template #header>
            <div
              class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <h5 class="m-0" v-if="!iscoach">Coaches</h5>
              <h5 class="m-0" v-if="iscoach">Users</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search"></i>
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Search..."
                />
              </span>
            </div>
          </template>

          <Column
            headerStyle="min-width: 4rem; text-align: center"
            bodyStyle="text-align: center; overflow: visible"
          >
            <template #body="slotProps">
              <Button
                type="button"
                icon="pi pi-comment"
                class="p-button-rounded p-button-secondary p-button-outlined p-button-sm"
                v-on:click="comment(slotProps.data.id)"
              ></Button>
            </template>
          </Column>
          <Column field="name" filterField="name" :header="iscoach ? 'User' : 'Coach'">
            <template #body="slotProps">
              <div
                class="flex"
                id="searchtabdatatable"
                style="align-items: center"
              >
                <!--<Image
                                :src="'images/' + slotProps.data.id + '.jpg'"
                                :preview=true
                                :imageStyle="{'border-radius': '50px', 'width': '50px'}"
                                onerror="javascript:this.src='images/coachera-logo-sm.png'"
                            />-->
                <!--<Avatar
                                :image="'images/' + slotProps.data.id + '.jpg'"
                                size="large"
                                shape="circle"
                                onerror="javascript:this.src='images/coachera-logo-sm.png'"
                                ></Avatar>-->
                <!--<img :src="'images/' + slotProps.data.id + '.jpg'" onerror="javascript:this.src='images/coachera-logo-sm.png'" width="32" height="32" style="vertical-align: middle;border-radius:30px;" />-->
                <!--<div class="p-avatar-custom">
                                <img :src="'images/' + slotProps.data.id + '.jpg'" onerror="javascript:this.src='images/coachera-logo-sm.png'" />
                            </div>-->
                <div
                  class="p-avatar-custom"
                  :style="{
                    backgroundImage:
                      'url(images/' +
                      slotProps.data.id +
                      '.jpg), url(images/ludi_placeholder.png)',
                  }"
                >
                  <!--<img :src="'images/' + slotProps.data.id + '.jpg'" onerror="javascript:this.src='images/coachera-logo-sm.png'" />-->
                </div>
                <span
                  style="
                    width: 50%;
                    display: inline-block;
                    margin-left: 0.5em;
                    vertical-align: middle;
                  "
                  class="image-text"
                >
                  <router-link
                    v-if="iscoach"
                    :to="{
                      name: 'userprofile',
                      params: { uid: slotProps.data.id },
                    }"
                    target="_blank"
                  >
                    {{ slotProps.data.name }}
                  </router-link>
                  <router-link
                    v-else
                    :to="{
                      name: 'coachprofile',
                      params: { uid: slotProps.data.id },
                    }"
                    target="_blank"
                  >
                    {{ slotProps.data.name }}
                  </router-link>
                </span>
              </div>
            </template>
            <template #filter="{ filterCallback }">
                <InputText type="text" v-model="filters['name'].value" @input="filterCallback()" class="p-column-filter" />
            </template>
            <template #filterclear="{}">
                <Button type="button" icon="pi pi-times" @click="filterClear('name')" label="Clear" class="p-button-secondary p-button-sm"></Button>
            </template>
          </Column>
          <!--<Column field="price" header="Rate per hour" :sortable="true">
                        <template #body="slotProps">
                            {{formatCurrency(slotProps.data.price)}}
                        </template>
                    </Column>-->
          <Column
            v-if="windowWidth > 650"
            field="description"
            filterField="nutshell"
            header="Description"
            :sortable="false"
          >
            <template #body="slotProps">
              <span v-if="iscoach" class="textarea">{{
                slotProps.data.about
              }}</span>
              <span v-else class="textarea">{{ slotProps.data.nutshell }}</span>
            </template>
            <template #filter="{ filterCallback }">
                <InputText type="text" v-model="filters['nutshell'].value" @input="filterCallback()" class="p-column-filter" />
            </template>
            <template #filterclear="{}">
                <Button type="button" icon="pi pi-times" @click="filterClear('nutshell')" label="Clear" class="p-button-secondary p-button-sm"></Button>
            </template>
          </Column>
          <Column :showFilterOperator="false" :showFilterMatchModes="false" field="pricerange" header="Price range" :sortable="true" v-if="!iscoach">
            <template #body="slotProps">
              {{ slotProps.data.pricerange }}
            </template>
            <template #filter="{}">
                <!--<InputText type="text" v-model="filters['pricerange'].value" @input="filterCallback()" class="p-column-filter" />-->

                <Dropdown
                  v-model="filters['pricerange'].value"
                  :options="dropdownValuesPR"
                  optionLabel="name"
                  placeholder="Select price range" 
                />

            </template>
            <template #filterclear="{}">
                <Button type="button" icon="pi pi-times" @click="filterClear('pricerange')" label="Clear" class="p-button-secondary p-button-sm"></Button>
            </template>
          </Column>

          <Column :showFilterOperator="false" :showFilterMatchModes="false" field="preferred_coaching" header="Type of coaching" :sortable="true" v-if="!iscoach">
            <template #body="slotProps">
              {{ slotProps.data.preferred_coaching }}
            </template>
            <template #filter="{}">
                <Dropdown
                  v-model="filters['preferred_coaching'].value"
                  :options="dropdownValuesPC"
                  optionLabel="name"
                  placeholder="Select type of coaching" 
                />
            </template>
            <template #filterclear="{}">
                <Button type="button" icon="pi pi-times" @click="filterClear('preferred_coaching')" label="Clear" class="p-button-secondary p-button-sm"></Button>
            </template>
          </Column>

          <Column
            v-if="!iscoach"
            field="rating"
            header="Reviews"
            :sortable="true"
          >
            <template #body="slotProps">
              <Rating
                :modelValue="slotProps.data.rating"
                :readonly="true"
                :cancel="false"
              />
            </template>
          </Column>
          <Column :showFilterOperator="false" :showFilterMatchModes="false" field="metric" header="Match score" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.metric }}
            </template>
            <template #filter="{}">
                <Slider v-model="filters['metric'].value" range class="m-3"></Slider>
                <div class="flex align-items-center justify-content-center px-2">
                    <span class="m-3">{{filters['metric'].value ? filters['metric'].value[0] : 0}}</span>
                    <span class="m-3">{{filters['metric'].value ? filters['metric'].value[1] : 100}}</span>
                </div>
            </template>
            <template #filterclear="{}">
                <Button type="button" icon="pi pi-times" @click="filterClear('metric')" label="Clear" class="p-button-secondary p-button-sm"></Button>
            </template>
          </Column>

        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from "primevue/api";
import UserService from "../service/UserService";
import {FilterService} from 'primevue/api';

export default {
  data() {
    return {
      myOptions: {
        useKeyboardNavigation: true,
        highlight: true,
        placement: "right",
        // enableScrolling: false,
        labels: {
          buttonSkip: "Skip",
          buttonPrevious: "Previous",
          buttonNext: "Next",
          buttonStop: "Finish",
        },
      },
      steps_coach: [
        {
          target: "#searchtabdatatable",
          content: `This is the search pane. Here you can search among the list of users who match your priorities and start a conversation with them by clicking the gray comment icon. 
            <br/> 
            If the list is empty you can always modify your priorities.`,
          params: {
            placement: "left-start",
          },
        },
        {
          target: "#conversations",
          content: "Here you can view all your conversations with the users.",
          // params: {
          //   placement: "top",
          // },
        },
        {
          target: "#profile",
          content: "From this tab you can view and edit your profile.",
          // params: {
          //   placement: "top",
          // },
        },
        {
          target: "#redo_priorities",
          content: "Here you can modify your coaching priorities. Now go check out some of the awesome users on our platform! Maybe an interesting lead will arise.",
          // params: {
          //   placement: "top",
          // },
        },
      ],
      steps_user: [
        {
          target: "#searchtabdatatable",
          content: `This is the search pane. Here you can search among the list of coaches who match your goals (set according to the questionnaire) and start a conversation with them by clicking the gray comment icon. 
            <br/> 
            If the list is empty you can always redo your Questionnaire.`,
          params: {
            placement: "left-start",
          },
        },
        {
          target: "#conversations",
          content:
            "Here you can view all your conversations with your coaches.",
          // params: {
          //   placement: "top",
          // },
        },
        {
          target: "#profile",
          content: "From this tab you can view and edit your profile.",
          // params: {
          //   placement: "top",
          // },
        },
        {
          target: "#redo_questionnaire",
          content: "Here you can redo your questionnaire. Now go check out some of the awesome coaches on our platform!",
          // params: {
          //   placement: "top",
          // },
        },
      ],
      products: null,
      product: {},
      filters: {
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        // 'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        'pricerange': {value: null, matchMode: FilterMatchMode.EQUALS},
        'preferred_coaching': {value: null, matchMode: FilterMatchMode.EQUALS},
        'nutshell': {value: null, matchMode: FilterMatchMode.CONTAINS },
        'metric': {value: null, matchMode: FilterMatchMode.BETWEEN }
      },
      iscoach: false,
      windowWidth: window.innerWidth,
      loading1: true,
      dropdownValuesPR: [
        { name: "<50$" },
        { name: "50-80$"},
        { name: "80-120$" },
        { name: "120-200$" },
        { name: "200-300$" },
        { name: "300$+" },
      ],
      dropdownValuesPC: [
        { name: "Group" },
        { name: "Subscription (3 months)"},
        { name: "Subscription (6 months)" },
        { name: "Subscription (>6 months)" },
        { name: "Individual Sessions" },
        { name: "Other" },
      ],
    };
  },
  userService: null,
  created() {
      FilterService.register('myfilter', (a, b) => {
          if (b == null) {
            return true;
          }
          console.log(a, b);
          return a === b['name'];
      });
    this.userService = new UserService();
    this.initFilters();
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  mounted() {
    this.userService.getMatch().then((data) => {
      this.loading1 = false;
      for (var i in data) {
        if (data[i].about == undefined) {
          continue;
        }
        if (data[i].about.length > 300) {
          data[i].about = data[i].about.slice(0, 300) + "...";
        }
      }
      this.products = data;
    });
    this.userService.getUser(-1).then((data) => {
      this.iscoach = data["coach"];
    });

    if (!this.userService.getTutorialDone()) {
      setTimeout(() => {
        this.$tours["myTour"].start();
        this.userService.setTutorialDone(true);
      }, 1000);
    }
  },
  methods: {
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    initFilters() {
      this.filters = {
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        // 'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        // 'pricerange': {value: null, matchMode: FilterMatchMode.EQUALS},
        'pricerange': {value: null, matchMode: 'myfilter'},
        'preferred_coaching': {value: null, matchMode: FilterMatchMode.EQUALS},
        'nutshell': {value: null, matchMode: FilterMatchMode.CONTAINS },
        'metric': {value: null, matchMode: FilterMatchMode.BETWEEN }
      };
    },
    filterClear(key) {
        console.log(key);
        this.filters[key].value = null;
    },
    test(data) {
        console.log(data);
        console.log(this.filters);
    },
    comment(uid) {
      this.userService.saveConversation({ uid: uid }).then((data) => {
        this.$router.push({ name: "conversations", params: { uid: uid } });
      });
    },
  },
};
</script>

<style lang="scss">
@media (max-width: 450px) {
  .layout-wrapper .layout-main-container {
    padding-left: 0rem !important;
  }
  .layout-main-container {
    padding: 7rem 0rem 2rem 0rem !important;
  }
}
// VUE3 Tour Stylings--------------------------------
.v-step {
  background: #fff !important;
  color: #3f3f4c !important;
  border-radius: 0.7rem !important;
  // filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
  // padding: 1rem;
  text-align: justify !important;
  // z-index: 10000;
}
.v-step__button {
  color: #ffffff !important;
  background: #6366f1 !important;
  border: 1px solid #6366f1 !important;
  padding: 0.75rem 1.25rem !important;
  font-size: 1rem !important;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s !important;
  border-radius: 6px !important;
  height: auto !important;
}
// .v-step .v-step__arrow {
//   // border-color: #ffffff !important;
// }
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4) !important;
}
</style>
<style scoped lang="scss">
@media (max-width: 450px) {
  .grid {
    margin: 0px !important;
  }
  .card {
    padding: 0px !important;
  }
}
img {
  width: 32px;
  border-radius: 30px;
}
</style>
<style>
.p-avatar.p-avatar-image {
  overflow: hidden;
}
.p-avatar img {
  height: 40px !important;
  width: auto !important;
}
.p-avatar-custom {
  overflow: hidden;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  /* border: 1px solid black; */

  background-size: cover;
  background-position: center;
}
.p-avatar-custom img {
  width: 100%;
}
.textarea {
  white-space: pre-wrap;
  min-width: 180px;
  display: inline-block;
}
.p-rating {
  min-width: 90px;
}
.v-step[x-placement^="left-start"] {
  top: 200px!important;
}
.v-step__button {
    background: #3EB5C7!important;
    border: 1px solid #3EB5C7!important;
    margin: 0;
    display: inline-flex;
    cursor: pointer;
    align-items: center;
    vertical-align: bottom;
    text-align: center;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    color: #ffffff;
    background: #4F46E5;
    border: 1px solid #4F46E5;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    transition: none;
    border-radius: 0.375rem;
}
.p-rating .p-rating-icon {
    margin-left: 0.1rem;
}
.p-datatable .p-datatable-tbody > tr > td {
    padding: 1rem 0rem 1rem 1rem;
}
.p-datatable .p-datatable-thead > tr > th {
    padding: 0.75rem 1rem;
}
.p-button-sm {
    width: 2.1rem!important;
    height: 2.1rem!important;
}
</style>
